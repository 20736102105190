var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Numéro de salle")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.meetingRoomForm.meetingRoomName,
                              expression: "meetingRoomForm.meetingRoomName"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.meetingRoomForm.meetingRoomName.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: {
                            value: _vm.meetingRoomForm.meetingRoomName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.meetingRoomForm,
                                "meetingRoomName",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted &&
                        _vm.$v.meetingRoomForm.meetingRoomName.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.meetingRoomForm.meetingRoomName.required
                                ? _c("span", [
                                    _vm._v("Le numéro est obligatoire.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Location")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.meetingRoomForm.meetingRoomLocation,
                              expression: "meetingRoomForm.meetingRoomLocation"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.meetingRoomForm.meetingRoomLocation.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: {
                            value: _vm.meetingRoomForm.meetingRoomLocation
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.meetingRoomForm,
                                "meetingRoomLocation",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted &&
                        _vm.$v.meetingRoomForm.meetingRoomLocation.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.meetingRoomForm.meetingRoomLocation
                                .required
                                ? _c("span", [
                                    _vm._v("Le location est obligatoire.")
                                  ])
                                : _vm._e(),
                              !_vm.$v.meetingRoomForm.meetingRoomLocation
                                .maxLength
                                ? _c("span", [
                                    _vm._v(
                                      "La taille du champs est limitée a 64 charactères."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Capaticé totale")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.meetingRoomForm.meetingRoomCapacity,
                              expression: "meetingRoomForm.meetingRoomCapacity"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.meetingRoomForm.meetingRoomCapacity.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: {
                            value: _vm.meetingRoomForm.meetingRoomCapacity
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.meetingRoomForm,
                                "meetingRoomCapacity",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted &&
                        _vm.$v.meetingRoomForm.meetingRoomCapacity.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.meetingRoomForm.meetingRoomCapacity
                                .required
                                ? _c("span", [
                                    _vm._v("La Capaticé est obligatoire.")
                                  ])
                                : _vm._e(),
                              !_vm.$v.meetingRoomForm.meetingRoomCapacity
                                .maxLength
                                ? _c("span", [
                                    _vm._v(
                                      "La taille du champs est limitée a 64 charactères."
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.$v.meetingRoomForm.meetingRoomCapacity
                                .numeric
                                ? _c("span", [
                                    _vm._v("La Capaticé n'est pas valide.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Enregistrer")]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }