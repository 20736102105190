<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
  required, maxLength, numeric
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouvelle salle de réunions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouvelle salle de réunions",
      items: [
        {
          text: "Infrastructures",
          href: "/"
        },
        {
          text: "Salles de réunions",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],
      meetingRoomForm: {
        meetingRoomName: "",
        meetingRoomLocation: "",
        meetingRoomCapacity: "",
        // roomPhone: "",
      },
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    meetingRoomForm: {
      meetingRoomName: { required, maxLength: maxLength(64) },
      meetingRoomLocation: { required, maxLength: maxLength(64) },
      meetingRoomCapacity: { required, maxLength: maxLength(64), numeric },
    },
    
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http.post('/infrastructure/meeting_room/store', this.meetingRoomForm)

      .then((res) => {
          var status = res.data.original.status;
          switch(status){
            case 200: 
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              setTimeout(function(){ router.push({ name: 'base.infrastructures.meeting_rooms' }); }, 3000);
              
            break;

            case 500: 
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
            break;
          }
      })
      .catch((error) => {
          Swal.fire("Erreur!", error, "error");
      }).finally(() => {
      });
    }
    },

   
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Numéro de salle</label>
                    <input
                      id="deptName"
                      v-model="meetingRoomForm.meetingRoomName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.meetingRoomForm.meetingRoomName.$error }"
                    />
                    <div v-if="submitted && $v.meetingRoomForm.meetingRoomName.$error" class="invalid-feedback">
                      <span v-if="!$v.meetingRoomForm.meetingRoomName.required">Le numéro est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Location</label>
                    <input
                      id="deptName"
                      v-model="meetingRoomForm.meetingRoomLocation"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.meetingRoomForm.meetingRoomLocation.$error }"
                    />
                    <div v-if="submitted && $v.meetingRoomForm.meetingRoomLocation.$error" class="invalid-feedback">
                      <span v-if="!$v.meetingRoomForm.meetingRoomLocation.required">Le location est obligatoire.</span>
                      <span v-if="!$v.meetingRoomForm.meetingRoomLocation.maxLength">La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Capaticé totale</label>
                    <input
                      id="deptName"
                      v-model="meetingRoomForm.meetingRoomCapacity"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.meetingRoomForm.meetingRoomCapacity.$error }"
                    />
                    <div v-if="submitted && $v.meetingRoomForm.meetingRoomCapacity.$error" class="invalid-feedback">
                      <span v-if="!$v.meetingRoomForm.meetingRoomCapacity.required">La Capaticé est obligatoire.</span>
                      <span v-if="!$v.meetingRoomForm.meetingRoomCapacity.maxLength">La taille du champs est limitée a 64 charactères.</span>
                      <span v-if="!$v.meetingRoomForm.meetingRoomCapacity.numeric">La Capaticé n'est pas valide.</span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>